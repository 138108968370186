[styled='primary']:hover,
[styled='secondary']:hover {
  box-shadow: 1px 2px 1px 0px;
}

[styled='primary'] {
  color: var(--primaryColorLight);
}

[styled='primary']:hover {
  color: var(--primaryColor);
}

[styled='secondary']:hover {
  color: var(--primaryColorDark);
  background-color: var(--primaryColorLight) !important;
}

a.button-icon i {
  font-size: 1.5rem;
  width: auto;
  height: auto;
  padding: 8px;
  color: var(--primaryColor);
}
.button-icon[styled='primary-icon'] {
  background-color: transparent;
  box-shadow: none;
  width: max-content;
  height: max-content;
  margin: 0;
}

.button-icon[styled='primary-icon'] i {
  font-size: 1.5rem;
  width: auto;
  height: auto;
  padding: 8px;
  color: var(--primaryColor);
}

button span {
  font-family: 'nunitoregular';
  text-transform: capitalize;
  font-weight: bold;
  /* font-size: 1rem; */
}

.button-continue {
  max-width: 400px;
  width: 100%;
  margin: 1.5rem 0;
  font-size: 1.2rem;
  padding: 5px 8px;
}

.button-func {
  background: none;
  border: none;
  border-radius: 50%;
  transition: background 0.5s ease;
}
.button-func:hover {
  background-color: var(--primaryColorLight);
}
