@font-face {
    font-family: 'nunitobold';
    src: url('../../assets/fonts/nunito-bold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/nunito-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  @font-face {
    font-family: 'nunitoextrabold';
    src: url('../../assets/fonts/nunito-extrabold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/nunito-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  @font-face {
    font-family: 'nunitoregular';
    src: url('../../assets/fonts/nunito-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/nunito-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  @font-face {
    font-family: 'nunitolight';
    src: url('../../assets/fonts/nunito-light-webfont.woff2') format('woff2'),
         url('../../assets/fonts/nunito-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

