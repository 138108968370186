@import "../src/utilities/style/fonts.css";
@import "../src/utilities/style/inputs.css";
@import "../src/utilities/style/modal.css";
@import "../src/utilities/style/table.css";
@import "../src/utilities/style/buttons.css";
@import "../src/utilities/style/link.css";

:root {
  --primaryColorDark: #290777;
  /* --primaryColorDark: transparent; */
  --primaryColor: #5210bc;
  --primaryColorLight: #d9d6f7;

  --secondaryColorDark: #00ebae;
  --secondaryColor: #00cc96;
  --secondaryColorLight: #e3fffa;

  --boxShadowNav: none;
  --bgFieldInput: white;
  --bgFieldInputFocus: white;

  --bgBody: #f4f4f4;
  /* --txtBody: var(--primaryColor); */

  --btnColorTable: #cee4f6;

  --textSizeLabel: 1rem;

  /*BUTTONS*/
  --borderRadiusBtn: 30px;
  --minSizeBtnIcon: auto;

  --brFieldInput: var(--borderRadiusBtn);

  --txtTitle: var(--primaryColorDark);
  --textColor: var(--primaryColorDark);

  /*INPUTS*/
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-image: url("../src/assets/images/bgBody.png");
  background-size: cover;
  /* background-repeat: no-repeat; */
  overflow: auto;
}
/* body {
  background-image: url("../src/assets/images/fondo.jpg");
  background-size: cover;
  background-position: center;
  overflow: auto;
}

body.bg_2 {
  background-image: url("../src/assets/images/fondo_2.png");
}

body.bg_3 {
  background-image: url("../src/assets/images/fondo_3.png");
}

body.bg_4 {
  background-image: url("../src/assets/images/fondo_4.png");
} */

.o-main {
  display: grid;
  grid-template: max-content 1fr max-content / 1fr;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "nunitoextrabold" !important;
  color: var(--txtTitle);
}

p {
  line-height: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--primaryColorLight);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: var(--primaryColorLight);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--primaryColorLight);
}
