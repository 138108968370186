@import '../src/utilities/style/fonts.css';

body {
  margin: 0;
  font-family: 'nunitoregular' !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a[disabled]{
  pointer-events: none;
}

.m-a{
  display: block;
  margin: 0 auto;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

