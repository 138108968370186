.ui-gridTable{
    background-color: white;
    padding: 10px 20px;
    /* min-width: 700px; */
}

.ui-gridTable-head{
    box-shadow: none;
    border-bottom: 1px solid;
    border-radius: 0;
    grid-gap: .8rem 0;
    font-weight: bold;
}

.ui-gridTable-body{
    grid-gap: .8rem 0;
    display: block;
    word-break: break-all;
}

.ui-gridTable-body-row{
    border-bottom: 1px solid;
    border-radius: 0;
    align-items: center;
    justify-content: center;
}

.ui-scroll{
    max-width: calc(100vw - 10%);
    background-color: white;
}