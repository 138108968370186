.InputItem {
  width: 200px;
  height: 36px;
  border: 1px solid var(--primaryColorDark);
  margin: 0;
}
.InputItem i {
  left: 6px;
  top: 7px;
  font-size: 1.2rem;
  color: var(--primaryColor);
}
.InputItem span {
  left: 30px;
}
.InputItem input {
  padding-bottom: 16px;
  padding-left: 30px;
  padding-right: 16px;
}
.InputItem i ~ input {
  padding-bottom: 16px;
  padding-left: 30px;
  padding-right: 16px;
}

.InputItem[data-status='active'] {
  border-bottom: 1px solid;
}
.SelectItem {
  border-radius: var(--borderRadiusBtn);
  border: 1px solid var(--primaryColor);
  overflow: hidden;
  box-shadow: none;
  width: 200px;
}
.SelectItem::after {
  content: '';
  width: 15px;
  height: 15px;
  background-image: url('../../assets/images/icons/rol.svg');
  position: absolute;
  top: 11px;
  left: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.SelectItem:hover {
  box-shadow: none;
}
.SelectItem select {
  top: -3px;
  padding-left: 30px;
  font-size: 0.9rem;
  height: 36px;
}

.SelectItem select:hover ~ div {
  background-color: white;
}

.SelectItem select:hover ~ div i {
  color: var(--primaryColor);
}
.SelectItem div {
  background: white;
}
.SelectItem div i {
  font-size: 1.7rem;
  color: var(--primaryColor);
}

.c-video {
  max-width: 700px;
  width: 100%;
  margin: 2rem 0;
}
.c-video-controls {
  height: 26px;
  bottom: 3px;
  left: 0px;
  background: var(--primaryColorDark);
}
.c-video-progress {
  left: 0;
}
video {
  border: none;
}
label[data-status='active'] span {
  transform: translateY(-69%) scale(0.65) !important;
}

input[type='checkbox']:checked ~ div {
  background-color: var(--primaryColorLight) !important;
}
